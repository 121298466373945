import { ITableListItem } from '@/components/type';
import {
  EEdgeType,
  EElementType,
  ERelationType,
  IEntity,
  ILineageDataRes,
  ILineageQuery,
  IResRelation,
  ITableProperties,
} from '@/services/lineage/type';
import { toast as message } from 'react-toastify';
import { cookie } from '@/constants/cookie';
import { customAlphabet } from 'nanoid';
import { routerMap } from '@/constants';
import { getEdgeType } from './lineageData';
import { EEntityType as EGlobalEntityType } from '../typings';
import {
  EDashType,
  IUserDatabase,
  IUserEdge,
  TInputEntity,
} from '@/components/TaskLineageView/typings';
import { EEntityType } from '@/components/TaskLineageView/components/type';

const timeArr = [
  'createTime',
  'updateTime',
  'gmtRun',
  'startTime',
  'endTime',
  'gmtCreate',
  'gmtUpdate',
  'collectTime',
];
// 格式化tableList值
export const handleTableDataSource = (data: ITableListItem[]) => {
  return data.map((item: any) => {
    /* eslint-disable */
    for (let key in item) {
      const value = item[key];
      // 把true转为是
      if (value === true) {
        item[key] = '是';
      }
      // 把false 转为否
      if (value === false) {
        item[key] = '否';
      }
      // 处理数据为空的情况
      if (value !== false && !value && value !== 0) {
        item[key] = '-';
      }
      // 格式化时间
      if (timeArr.includes(key)) {
        item[key] = handleTimeNoSecondFormat(value);
      }
    }
    /* eslint-disable */
    return item;
  });
};

// 格式化时间
export const handleTimeFormat = (time?: string | number) => {
  if (!time) return '-';
  const formatTime = new Date(+time).toLocaleString().replaceAll('/', '-');
  return formatTime === 'Invalid Date' ? '-' : formatTime;
};

export enum ETimeType {
  'YY_MM_DD' = 'YYYY-MM-DD',
  'YY_MM_DD_HH_MM' = 'YYYY-MM-DD HH:MM',
  'YY_MM_DD_HH_MM_SS' = 'YYYY-MM-DD HH:MM:SS',
}

function formatTime(time: number, type: ETimeType) {
  const date = new Date(time);
  const hour = date.getHours();
  const hourStr = hour < 10 ? '0' + hour : '' + hour;
  const minutes = date.getMinutes();
  const minutesStr = minutes < 10 ? '0' + minutes : '' + minutes;
  const seconds = date.getSeconds();
  const secondsStr = seconds < 10 ? '0' + seconds : '' + seconds;
  switch (type) {
    case ETimeType.YY_MM_DD:
      return (
        date.getFullYear() + '-' + (date.getMonth() + 1) + '-' + date.getDate()
      );
    case ETimeType.YY_MM_DD_HH_MM:
      return (
        date.getFullYear() +
        '-' +
        (date.getMonth() + 1) +
        '-' +
        date.getDate() +
        ' ' +
        hourStr +
        ':' +
        minutesStr
      );
    case ETimeType.YY_MM_DD_HH_MM_SS:
      return (
        date.getFullYear() +
        '-' +
        (date.getMonth() + 1) +
        '-' +
        date.getDate() +
        ' ' +
        hourStr +
        ':' +
        minutesStr +
        ':' +
        secondsStr
      );
  }
}

function commonFormatTime(
  time: string | number,
  type: ETimeType = ETimeType.YY_MM_DD_HH_MM,
) {
  if (!time) return '-';
  const resTime = formatTime(+time, type);
  return resTime === 'Invalid Date' ? '-' : resTime;
}

export function formatTimeToDay(time: string | number) {
  return commonFormatTime(time, ETimeType.YY_MM_DD);
}

export function formatTimeToSecond(time: string | number) {
  return commonFormatTime(time, ETimeType.YY_MM_DD_HH_MM_SS);
}

// 格式化时间 年-月-日 时:分
export const handleTimeNoSecondFormat = (time: string | number) => {
  return commonFormatTime(time);
};

// 格式化接口返回的对象
export const formatObjValue = (obj: { [key: string]: any }) => {
  for (let key in obj) {
    const value = obj[key];

    // 处理数据为空的情况
    if (!value) {
      obj[key] = '-';
    }

    // 格式化时间
    if (timeArr.includes(key)) {
      obj[key] = handleTimeNoSecondFormat(value);
    }
  }

  return obj;
};

// 保存在没有权限的情况下，跳转的url
export const saveNoAuthUrl = () => {
  const hash = window.location.hash.replace(/^\#/, '');
  localStorage.setItem(cookie.REDIRECT_URL, hash);
};

export function transGraphData(
  res: ILineageDataRes,
  fetchInfo: ILineageQuery,
): {
  nodes: TInputEntity[];
  edges: IUserEdge[];
} {
  const { guids } = fetchInfo;
  const { entities: resNodes, relations } = res;
  const fetchArr = commonSplitGuid(guids[0]);
  fetchArr.pop();
  const baseTableGuid = fetchArr.join('.');
  const lastIDSets: Set<string> = new Set();
  resNodes.forEach((table) => lastIDSets.add(table.guid));

  let nodes: TInputEntity[] = groupByTable(resNodes, baseTableGuid);
  const edges: IUserEdge[] = [];
  const edgesMap: Map<string, IUserEdge> = new Map();
  if (relations.length) {
    relations.forEach((item) => {
      const { relationTypeCode, srcGuid, dstGuid, fold } = item;
      if (isCircleEdge(item)) return;
      const { edgeType, relationType } = getEdgeType(relationTypeCode);

      if (edgesMap.has(`${srcGuid}-${dstGuid}`)) {
        const { type } = edgesMap.get(`${srcGuid}-${dstGuid}`)!;
        // 出现了重复的关系，直接跳过
        if (type === relationType) return;
        // 出现了不同类型的关系，改为 ALL
        edgesMap.get(`${srcGuid}-${dstGuid}`)!.type = EDashType.ALL;
        return;
      }
      const srcEntityType =
        edgeType === EEdgeType.T_T ? EElementType.TABLE : EElementType.COLUMN;
      const dstEntityType =
        edgeType === EEdgeType.T_T ? EElementType.TABLE : EElementType.COLUMN;
      const srcId = parseGuid(srcGuid, srcEntityType);
      const dstId = parseGuid(dstGuid, dstEntityType);
      const edge: IUserEdge = {
        edgeType,
        srcId,
        dstId,
        type: relationType,
        fold,
      };
      edgesMap.set(`${srcGuid}-${dstGuid}`, edge);
      edges.push(edge);
    });
  }

  return {
    edges,
    nodes,
  };
}

export function deparseGuid(guid: string) {
  // if (guid) {
  //   return guid.replace('#', '.');
  // }
  return guid;
}

// 把输入的 database.table.column 转变为 database#table.column格式
export function parseColumnGuid(guid: string) {
  if (!guid.includes('.')) return guid;
  const strSplit = commonSplitGuid(guid);
  if (strSplit.length < 3) {
    throw new Error('错误的列');
  }
  return guid;
}

// 判断是否是自环边
export function isCircleEdge(edge: IResRelation) {
  const { srcGuid: src, dstGuid: dst, relationTypeCode } = edge;
  if (src === dst) return true;
  if (
    relationTypeCode === ERelationType.ColumnDirectColumn ||
    relationTypeCode === ERelationType.ColumnIndirectColumn
  ) {
    return getTableIdFromColumnId(src) === getTableIdFromColumnId(dst);
  }
  return false;
}

// 把输入的 database.table 转变为 database#table格式
export function parseTableGuid(guid: string) {
  if (!guid.includes('.')) return guid;
  const strSplit = commonSplitGuid(guid);
  if (strSplit.length < 2) {
    throw new Error('错误的表');
  }
  return guid;
}

export function parseGuid(guid: string, typeCode: EElementType) {
  if (typeCode === EElementType.TABLE) {
    return parseTableGuid(guid);
  }
  if (typeCode === EElementType.COLUMN) {
    return parseColumnGuid(guid);
  }
  return guid;
}

export function commonSplitGuid(guid: string) {
  let otherSplit = guid.split('`');
  if (otherSplit.length > 1) {
    const res: string[] = [];
    otherSplit.forEach((item) => {
      if (!item.endsWith('.') && !item.startsWith('.')) {
        if (item !== '') res.push(`\`${item}\``);
      } else {
        const splitArr = item.split('.').filter((item) => item !== '');
        res.push(...splitArr);
      }
    });
    return res;
  }
  return guid.split('.');
}

export function getTableIdFromColumnId(id: string) {
  const idArr = commonSplitGuid(id);
  idArr.pop();
  return commonJoinGuid(idArr);
}

export function commonJoinGuid(splits: string[]) {
  return splits.join('.');
}

/**
 * 对一层的 table 做 group by 操作，把相同数据库的表放在一起
 * @param resNodes
 * @param originTableIndex
 * @returns
 */
export function groupByTable(
  resNodes: IEntity[],
  originTableIndex?: string,
): TInputEntity[] {
  const nodes: TInputEntity[] = [];
  resNodes.forEach((entity) => {
    const isStartNode = originTableIndex === entity.guid;

    if (entity.typeCode === 'Table' || entity.typeCode === 'View') {
      const idSplitArr = commonSplitGuid(entity.guid);
      idSplitArr.pop();
      const spaceGuid = idSplitArr.join('.');
      const len = idSplitArr.length;
      let name = 'Error Name';
      if (len >= 2) {
        name = `${idSplitArr[len - 2]} / ${idSplitArr[len - 1]}`;
      }

      let currentDatabase: IUserDatabase = {
        entityType: EEntityType.DATABASE,
        guid: spaceGuid,
        name,
        children: [],
      };

      const exist = nodes.some((node) => {
        if (node.guid === spaceGuid) currentDatabase = node as IUserDatabase;
        return node.guid === spaceGuid;
      });
      if (!exist) nodes.push(currentDatabase);

      resNodes.some((node) => {
        if (node.guid === entity.guid) {
          const props = node.properties as ITableProperties;
          currentDatabase.name = props?.group || props?.databaseName || name;
          currentDatabase.children.push({
            guid: parseTableGuid(node.guid),
            name: props.name,
            isStartNode,
            columnCount: props.columnCount,
            typeCode: node.typeCode,
            type: props.type,
            assetPath: props.assetPath,
            entityType: EEntityType.TABLE,
            datasourceType: props.datasourceType,
            children:
              props?.columns?.map((column) => {
                return {
                  dataType: column.dataType,
                  guid: parseColumnGuid(column.guid),
                  name: column.name || ' ',
                  description: column.description,
                  typeCode: column?.typeCode || 'Column',
                  position: column.position,
                };
              }) || [],
          });
        }
        return node.guid === entity.guid;
      });
    } else {
      nodes.push({
        guid: entity.guid,
        name: (entity.properties?.name as string) || entity.guid,
        entityType: EEntityType.CUSTOM_ENTITY,
        isStartNode,
        typeCode: entity.typeCode,
        properties: entity.properties,
      });
    }
  });
  return nodes;
}

// 文本复制
export const copyContent = (text: string) => {
  const inputEl = document.createElement('textarea');
  document.body.appendChild(inputEl);
  inputEl.value = text; // 添加需要复制的内容
  inputEl.select();
  const copySuccess = document.execCommand('Copy');
  if (copySuccess) {
    message.success('复制成功');
    inputEl.remove();
  } else {
    message.error('复制失败');
  }
};

/**
 * 千位向上取整
 * console.log(roundUpToTenThousand(113));  // 输出: 10000
 * console.log(roundUpToTenThousand(1131)); // 输出: 10000
 * console.log(roundUpToTenThousand(12312)); // 输出: 12400
 * console.log(roundUpToTenThousand(11999));  // 输出: 12000
 * @param num
 */
export function roundUpToTenThousand(num: number) {
  if (num <= 10000) {
    return 10000; // 10000及以下返回10000
  }

  // 计算千位以上的数值
  const thousands = Math.floor(num / 10000) * 10000;

  // 计算千位以上的最后两位
  const lastTwoDigits = num % 100;

  // 如果最后两位大于 0，则向上取整到千位以上的最后两位
  if (lastTwoDigits > 0) {
    return thousands + 100; // 向上取整到最后两位
  } else {
    return num; // 最后两位为 00，不需要向上取整
  }
}

/**
 * 获取唯一 ID
 * @returns 唯一的 ID
 */
export function getUniqId() {
  // 16位的长度，每秒生成1000个ID的话，240年时间才有1%的可能性产生一次 ID 冲突
  // 参考 https://zelark.github.io/nano-id-cc/
  const CHARACTER_LEN = 16;
  const alphabet =
    '0123456789ABCDEFGHIJKLMNOPQRSTUVWXYZ_abcdefghijklmnopqrstuvwxyz-!@';
  return customAlphabet(alphabet, CHARACTER_LEN)();
}

// 通过实体类型获取跳转的url和code
export const getCodeAndPathNameByEntityType = (type: EGlobalEntityType) => {
  switch (type) {
    case EGlobalEntityType.TASK:
      return {
        code: 'Task',
        pathName: routerMap.dataAssetTask.path,
      };
    case EGlobalEntityType.TABLE:
    case EGlobalEntityType.COLUMN:
      return {
        code: 'Table',
        pathName: routerMap.dataAssetTable.path,
      };
    case EGlobalEntityType.CUSTOM:
      return {
        code: 'Custom',
        pathName: routerMap.dataAssetCustom.path,
      };
    default:
      return {
        code: 'Table',
        pathName: routerMap.dataAssetTable.path,
      };
  }
};

export function getQueryParam(url: string, name: string) {
  name = name.replace(/[\[\]]/g, '\\$&');
  const regex = new RegExp('[?&]' + name + '(=([^&#]*)|&|#|$)');
  const results = regex.exec(url);

  if (!results) return null;
  if (!results[2]) return '';

  return decodeURIComponent(results[2].replace(/\+/g, ' '));
}

export * from './urlUtils';
