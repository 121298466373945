import {
  EDashType,
  TInnerNodeItem,
  TNodeItem,
} from '@/components/TaskLineageView/typings';
import Table from '@/components/TaskLineageView/model/Table';
import SQLGraph, {
  TEdgeMap,
  TNodeMap,
} from '@/components/TaskLineageView/model/SQLGraph';
import {
  getDirection,
  getDirectionTablesByTableId,
  IFilter,
} from '@/components/TaskLineageView/utils/map';
import Column from '@/components/TaskLineageView/model/Column';
import Edge from '@/components/TaskLineageView/model/Edge';
import { difference, uniq } from 'lodash';
// import { notification } from '@aloudata/aloudata-design';
import { toast as message } from 'react-toastify';
// import styles from '@/utils/index.less';
import { IEntity } from '@/services/lineage/type';
import { EDirection } from '@/typings';

/**
 * 根据扩展方向获取上下游Table，结果包含层级概念
 * @param startNodes
 * @param edgeMap
 * @param nodeMap
 * @param direction 方向
 * @param filter
 * @param {number} stepNumber 步数
 * @returns {Table[][]} 返回结果将包含传入的节点
 */
export function getLayerTablePath(
  startNodes: TInnerNodeItem[],
  edgeMap: TEdgeMap,
  nodeMap: TNodeMap,
  direction: EDirection,
  filter: IFilter,
  stepNumber: number = 1,
): TInnerNodeItem[][] {
  if (direction === EDirection.BOTH) {
    const leftGroup = getLayerTablePath(
      startNodes,
      edgeMap,
      nodeMap,
      EDirection.INPUT,
      filter,
      stepNumber,
    );
    const rightGroup = getLayerTablePath(
      startNodes,
      edgeMap,
      nodeMap,
      EDirection.OUTPUT,
      filter,
      stepNumber,
    );
    leftGroup[0] = leftGroup[0].concat(rightGroup[0]);
    let res = [...leftGroup.reverse(), ...rightGroup.slice(1)];
    res = res.map((item) => {
      return [...new Set(item)];
    });
    return res;
  }

  const step = stepNumber;
  const layerTables: TInnerNodeItem[][] = [];

  const currentTableIdSet = new Set<string>();
  startNodes?.forEach((startNode) => {
    currentTableIdSet.add(startNode.index);
  });

  const query = (
    curTableId: string,
    stepCount: number,
    prevTableIdSet: Set<string>,
  ) => {
    if (stepCount > step) return;
    const table = nodeMap.get(curTableId);
    if (prevTableIdSet.has(curTableId) || !table) {
      return;
    }
    const currentLayer =
      layerTables[stepCount] || (layerTables[stepCount] = []);
    currentLayer.push(nodeMap.get(curTableId) as TInnerNodeItem);
    const inputTables: Set<TInnerNodeItem> = new Set<TInnerNodeItem>();
    getDirectionTablesByTableId(
      curTableId,
      edgeMap,
      nodeMap,
      filter,
      direction,
    ).forEach((item) => {
      inputTables.add(item);
    });
    prevTableIdSet.add(curTableId);
    inputTables.forEach((item) => {
      const newSet = new Set(prevTableIdSet);

      if (newSet.size) query(item.index, stepCount + 1, newSet);
    });
  };

  currentTableIdSet.forEach((item) => {
    query(item, 0, new Set<string>());
  });

  return layerTables.map((item) => {
    return [...new Set(item)];
  });
}

/**
 * 根据扩展方向获取上下游Table
 * @param startNodes
 * @param edgeMap
 * @param nodeMap
 * @param direction 方向
 * @param {number} stepNumber 步数
 * @param filter
 * @returns {Table[][]} 返回结果将包含传入的节点
 */
export function getColumnPathNodesEdges(
  startNodes: Column[],
  edgeMap: TEdgeMap,
  nodeMap: TNodeMap,
  direction: EDirection,
  stepNumber: number = 1,
  filter: IFilter = {
    edgeType: EDashType.ALL,
  },
): {
  nodes: Column[];
  edges: Edge[];
} {
  if (direction === EDirection.BOTH) {
    const leftGroup = getColumnPathNodesEdges(
      startNodes,
      edgeMap,
      nodeMap,
      EDirection.INPUT,
      stepNumber,
      filter,
    );

    const rightGroup = getColumnPathNodesEdges(
      startNodes,
      edgeMap,
      nodeMap,
      EDirection.OUTPUT,
      stepNumber,
      filter,
    );

    return {
      nodes: uniq([...leftGroup.nodes, ...rightGroup.nodes]),
      edges: uniq([...leftGroup.edges, ...rightGroup.edges]),
    };
  }

  return getDFCLineage(
    stepNumber,
    startNodes,
    edgeMap,
    nodeMap,
    direction,
    filter,
  );
}

/**
 * 根据扩展方向获取上下游Table
 * @param startNodes
 * @param edgeMap
 * @param nodeMap
 * @param direction 方向
 * @param {number} stepNumber 步数
 * @param filter
 * @returns {Table[][]} 返回结果将包含传入的节点
 */
export function getTablePathNodesEdges(
  startNodes: TNodeItem[],
  edgeMap: TEdgeMap,
  nodeMap: TNodeMap,
  direction: EDirection,
  stepNumber: number = 1,
  filter: IFilter = {
    edgeType: EDashType.ALL,
  },
): {
  nodes: TNodeItem[];
  edges: Edge[];
} {
  if (direction === EDirection.BOTH) {
    const leftGroup = getTablePathNodesEdges(
      startNodes,
      edgeMap,
      nodeMap,
      EDirection.INPUT,
      stepNumber,
      filter,
    );
    const rightGroup = getTablePathNodesEdges(
      startNodes,
      edgeMap,
      nodeMap,
      EDirection.OUTPUT,
      stepNumber,
      filter,
    );
    return {
      nodes: uniq([...leftGroup.nodes, ...rightGroup.nodes]),
      edges: uniq([...leftGroup.edges, ...rightGroup.edges]),
    };
  }
  return getDFCLineage(
    stepNumber,
    startNodes,
    edgeMap,
    nodeMap,
    direction,
    filter,
  );
}

function getDFCLineage<T extends TNodeItem>(
  stepNumber: number,
  startNodes: T[],
  edgeMap: TEdgeMap,
  nodeMap: TNodeMap,
  direction: EDirection,
  filter: IFilter,
) {
  const step = stepNumber;
  const nodeSet = new Set<T>();
  const edgeSet = new Set<Edge>();

  const query = (cur: T, stepCount: number, prevIdSet: Set<string>) => {
    if (stepCount > step) return;
    if (prevIdSet.has(cur.index)) return;
    if (nodeSet.has(cur)) return;

    nodeSet.add(cur);

    const inputNodeSet = new Set<T>();
    const inputs = getDirection<T>(cur, edgeMap, nodeMap, filter, direction);
    inputs.nodes.forEach((item) => {
      inputNodeSet.add(item);
    });
    inputs.edges.forEach((item) => {
      edgeSet.add(item);
    });

    prevIdSet.add(cur.index);
    inputNodeSet.forEach((item) => {
      const newSet = new Set(prevIdSet);
      if (newSet.size) query(item, stepCount + 1, newSet);
    });
  };

  startNodes.forEach((item) => {
    query(item, 0, new Set<string>());
  });

  return {
    nodes: [...nodeSet],
    edges: [...edgeSet],
  };
}

// 表级别的收起功能，会收起所有相关的表列
export function removeGraphByTable(
  startNodes: TInnerNodeItem[],
  direction: EDirection,
  context: SQLGraph,
) {
  const { originEdgeMap, originNodeMap } = context.viewGraph;
  const res = getTablePathNodesEdges(
    startNodes,
    originEdgeMap,
    originNodeMap,
    direction,
    Number.MAX_SAFE_INTEGER,
  );
  startNodes.forEach((item) => {
    if (item instanceof Table) {
      const columnPathNodesEdges = getColumnPathNodesEdges(
        item.children,
        originEdgeMap,
        originNodeMap,
        direction,
        Number.MAX_SAFE_INTEGER,
      );
      // removed others columns and column->column edges
      context.viewGraph.remove(
        difference(columnPathNodesEdges.nodes, item.children),
        columnPathNodesEdges.edges,
      );
    }
  });

  // removed others tables and table->table edges
  context.viewGraph.remove(difference(res.nodes, startNodes), res.edges);
}

// 表列级别的收起功能，此方法只会收起所有相关的列和列-列的边
export function removeColumnByNode(
  startNodes: Column[],
  direction: EDirection,
  context: SQLGraph,
) {
  const res = getColumnPathNodesEdges(
    startNodes,
    context.viewGraph.originEdgeMap,
    context.viewGraph.currentNodeMap,
    direction,
    Number.MAX_SAFE_INTEGER,
  );
  context.viewGraph.remove(res.nodes, res.edges);
}

export function showNoMoreLinage(direction: EDirection) {
  message.info(
    `该节点无${direction === EDirection.INPUT ? '上游' : '下游'}血缘`,
    {
      position: 'bottom-right',
    },
  );
  // notification.open({
  //   duration: 1.5,
  //   message: `该节点无${direction === EDirection.INPUT ? '上游' : '下游'}血缘`,
  //   className: styles.notification,
  //   placement: 'bottomRight',
  // });
}

/*
 * 从后端返回的数据中，找出所有的环状链路
 */
// function findCyclicPaths(data: IResRelation[]) {
//   const paths: string[][] = [];
//   const linkMap: { [key: string]: boolean } = {};

//   function dfs(
//     currentPath: string[],
//     currentNode: string,
//     visited: Set<string>,
//   ) {
//     if (visited.has(currentNode)) {
//       const startIndex = currentPath.indexOf(currentNode);
//       if (startIndex !== -1) {
//         const cyclicPath = currentPath.slice(startIndex);

//         const pathStr = cyclicPath.join('/');
//         if (!linkMap[pathStr]) {
//           linkMap[pathStr] = true;
//           paths.push(cyclicPath);
//         }
//       }
//       return;
//     }

//     visited.add(currentNode);

//     const neighbors = data.filter(
//       (relation) => relation.srcGuid === currentNode,
//     );

//     for (const neighbor of neighbors) {
//       dfs(
//         [...currentPath, neighbor.srcGuid],
//         neighbor.dstGuid,
//         new Set(visited),
//       );
//     }

//     visited.delete(currentNode);
//   }

//   for (const relation of data) {
//     dfs([relation.srcGuid], relation.dstGuid, new Set());
//   }

//   return paths;
// }

// export function getLineageStatus(
//   res: ILineageDataRes,
//   directionMap: { [key: string]: EDirection },
// ): ELineageStatus[] {
//   const { entities, relations } = res;

//   const result: ELineageStatus[] = [];
//   // 先找出 relations 中开始节点
//   // 遍历relations，如果发现 srcGuid 不存在于dstGuid中，则为开始节点
//   let realSrcGuids: string[] = [];
//   const dstGuids: string[] = [];
//   for (const relation of relations) {
//     dstGuids.push(relation.dstGuid);
//   }

//   for (const relation of relations) {
//     if (!dstGuids.includes(relation.srcGuid)) {
//       realSrcGuids.push(relation.srcGuid);
//     }
//   }

//   realSrcGuids = [...new Set(realSrcGuids)];
//   const linkMap: { [key: string]: boolean } = {};

//   // 先处理环状链路，找出所有环状链路
//   const cyclePaths = findCyclicPaths(relations);

//   // 先做一次 tag 的判断，否则会漏掉一些场景
//   const isMultiPath = checkPathIsMultiPath(cyclePaths);
//   if (isMultiPath) {
//     result.push(ELineageStatus.MULTI_LINK);
//   }

//   const isMultiOutPath = checkPathIsMultiOutput(cyclePaths, entities);
//   if (isMultiOutPath) {
//     result.push(ELineageStatus.MULTI_OUTPUT);
//   }

//   // 根据上面找到的起始节点，找出所有路径，用数组表示，数组中的顺序即为链路的顺序

//   const allPaths = [...cyclePaths];
//   for (const entity of realSrcGuids) {
//     const queue = [[entity]];

//     while (queue.length > 0) {
//       const path = queue.shift();
//       const currentEntityGuid = path[path.length - 1];

//       const relationsFromCurrent = relations.filter(
//         (relation) => relation.srcGuid === currentEntityGuid,
//       );

//       if (relationsFromCurrent.length === 0) {
//         const pathStr = path.join('/');
//         if (!linkMap[pathStr]) {
//           linkMap[pathStr] = true;
//           allPaths.push(path);
//           // 如果之前没有过链路中断的case，就检查一下，否则的话就不需要检测了，节省性能
//           if (!result.includes(ELineageStatus.LINK_BREAK)) {
//             const isNotBreak = checkPathIsNotBreak(
//               path,
//               entities,
//               directionMap,
//             );
//             if (!isNotBreak) {
//               result.push(ELineageStatus.LINK_BREAK);
//             }
//           }

//           // 如果之前没有多输出 的 case，并且 allPaths 的数量大于 1，就检测一下
//           if (
//             !result.includes(ELineageStatus.MULTI_OUTPUT) &&
//             allPaths.length > 1
//           ) {
//             const isMulti = checkPathIsMultiOutput(allPaths, entities);
//             if (isMulti) {
//               result.push(ELineageStatus.MULTI_OUTPUT);
//             }
//           }
//         }
//       }

//       for (const relation of relationsFromCurrent) {
//         const nextEntityGuid = relation.dstGuid;
//         // 防止成环
//         if (!path.includes(nextEntityGuid)) {
//           queue.push([...path, nextEntityGuid]);
//         }
//       }
//     }
//   }

//   // 多链路资产必须得吧全部 path 找完才能检测，不然可能存在那种间接关系的，会误判
//   if (!result.includes(ELineageStatus.MULTI_LINK)) {
//     const isMulti = checkPathIsMultiPath(allPaths);
//     if (isMulti) {
//       result.push(ELineageStatus.MULTI_LINK);
//     }
//   }
//   return result;
// }

/**
 * 链路中断
 * 某一条链路中，存在任务内链路的源头不是物理表，或者存在任务内链路的末端不是物理表
 */
export function checkPathIsNotBreak(
  path: string[],
  entities: IEntity[],
  directionMap: { [key: string]: EDirection },
) {
  const firstEntity = entities.find((entity) => entity.guid === path[0]);
  const lastEntity = entities.find(
    (entity) => entity.guid === path[path.length - 1],
  );

  const existInput =
    firstEntity?.properties.type !== 'TEMP_TABLE' &&
    (directionMap[firstEntity?.guid] === EDirection.INPUT ||
      directionMap[firstEntity?.guid] === EDirection.BOTH);

  const existOutput =
    lastEntity?.properties.type !== 'TEMP_TABLE' &&
    (directionMap[lastEntity?.guid] === EDirection.OUTPUT ||
      directionMap[lastEntity?.guid] === EDirection.BOTH);
  return existInput && existOutput;
}

/**
 * 多输出资产
 * 判断是否存在两个及以上的输出物理表资产，只要 output 不是临时表，并且数量大于 1 就算是多输出资产
 */

export function checkPathIsMultiOutput(
  allPaths: string[][],
  entities: IEntity[],
) {
  let countOutput = 0;
  const outputCountMap = new Map();
  for (const path of allPaths) {
    const lastEntity = entities.find(
      (entity) => entity.guid === path[path.length - 1],
    );
    if (
      lastEntity?.properties.type !== 'TEMP_TABLE' &&
      !outputCountMap.has(lastEntity?.guid)
    ) {
      countOutput++;
      outputCountMap.set(lastEntity?.guid, true);
    }
  }
  return countOutput > 1;
}

/**
 * 多链路
 * 存在两条及以上的单独链路，链路之间没有任何关联资产；
 */

export function checkPathIsMultiPath(allPaths: string[][]): boolean {
  // 整体思路：先找到一个链路，拿这个作为参照物，去看其他的链路是否跟它没有任何关联，
  // 如果能找到一个链路它完全没关联的，就说明是多链路
  if (allPaths.length < 2) return false;

  // 先把所有的链路按照起始字母分组，把相同起点的都放在一起
  const groupedStartPaths: { [key: string]: string[] } = {};
  allPaths.forEach((path) => {
    // 以这个开头的全部放在一起
    const start = path[0];
    if (!groupedStartPaths[start]) {
      groupedStartPaths[start] = [...new Set(path)];
    } else {
      groupedStartPaths[start] = [
        ...groupedStartPaths[start],
        ...new Set(path),
      ];
    }
  });

  const groupedEndPaths: { [key: string]: string[] } = {};
  Object.values(groupedStartPaths).forEach((path) => {
    // 以这个结尾的全部放在一起
    const end = path[path.length - 1];
    if (!groupedEndPaths[end]) {
      groupedEndPaths[end] = [...new Set(path)];
    } else {
      groupedEndPaths[end] = [...new Set([...groupedEndPaths[end], ...path])];
    }
  });
  const calcPaths = Object.values(groupedEndPaths);
  if (calcPaths.length < 2) return false;

  // step1: 先找到一个起始和结束都是物理表的链路
  const referencePath: string[] | null = calcPaths[0];

  const newPaths = calcPaths.slice(1);
  // step2: 拿这个作为参照物，去看其他的链路是否跟它没有任何关联，
  let hasRelation = false;

  for (let i = 0; i < newPaths.length; i++) {
    const path = newPaths[i];
    for (let j = 0; j < path.length; j++) {
      if (referencePath.includes(path[j])) {
        hasRelation = true;
        break;
      }
    }
    // 只要找到一个链路没有关联的，就可以跳出循环了
    if (!hasRelation) break;
  }
  return !hasRelation;
}
