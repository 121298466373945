import { IRouteItem } from '@/router';

export const routerMap = {
  notFound: {
    path: '/404',
    name: 'notFound',
  },
  home: {
    path: '/home',
    name: '首页',
  },

  // 数据资产 ---------- start
  dataAssetHome: {
    path: '/dataAsset/home',
    name: '数据资产概览',
  },
  dataAssetTable: {
    path: '/dataAsset/table',
    name: '表详情页',
  },
  dataAssetTask: {
    path: '/dataAsset/task',
    name: '任务详情页',
  },
  dataAssetCustom: {
    path: '/dataAsset/custom',
    name: '自定义资产详情页',
  },
  // 数据资产 ----------- end

  // 资产分析--》资产盘点 ----------- start
  traceAnalysis: {
    path: '/assetAnalysis/traceAnalysis',
    name: '溯源分析',
  },
  cropAnalysis: {
    path: '/assetAnalysis/cropAnalysis',
    name: '溯源行裁剪分析', // 行级裁剪
  },
  changeImpactAnalysis: {
    path: '/assetAnalysis/changeImpactAnalysis',
    name: '变更影响分析',
  },
  changeImpactAnalysisDetail: {
    path: '/assetAnalysis/changeImpactAnalysis/:id',
    name: '变更影响分析详情', // Change Notification
  },
  lineageReport: {
    path: '/assetAnalysis/lineageReport',
    name: '资产血缘报告', // 资产血缘报告
  },
  // 资产分析 ----------- end

  // 资产打标 ----------- start
  tagSetting: {
    path: '/ingest/tag',
    name: '标签管理',
  },
  tagSettingDetail: {
    path: '/ingest/tag/detail',
    name: '标签管理详情',
  },

  // 资产管理 ----------- end

  // 策略中心 ---------- start
  policyCenter: {
    path: '/ingest/changeNotification',
    name: '变更预警',
  },
  // 策略中心 ---------- end

  // 采集管理 ----------- start
  ingestManagement: {
    path: '/ingest/management',
    name: '采集任务管理',
  },
  ingestManagementDetail: {
    path: '/ingest/management/detail',
    name: '采集任务管理详情',
  },
  ingestInstance: {
    path: '/ingest/instance',
    name: '运行实例管理',
  },
  ingestInstanceDetail: {
    path: '/ingest/instance/:id',
    name: '运行实例管理详情',
  },
  dataEntity: {
    path: '/ingest/entity',
    name: '实体管理',
  },
  dataEntityGroup: {
    path: '/ingest/entity/group',
    name: '实体组',
  },
  dataEntityGroupView: {
    path: '/ingest/entity/group/datasourceView',
    name: '查看数据来源',
  },
  dataEntityGroupDetail: {
    path: '/ingest/entity/group/detail',
    name: '已管理资产详情',
  },
  dataRelation: {
    path: '/ingest/relation',
    name: '关系管理',
  },
  dataRelationDetail: {
    path: '/ingest/relation/detail',
    name: '关系详情',
  },
  dataRelationDetailView: {
    path: '/ingest/relation/detail/datasourceView',
    name: '查看数据来源',
  },
  // 采集管理 ----------- end

  // 应用中心 ----------- start
  sceneCenter: {
    path: '/assetAnalysis/home',
    name: '场景中心',
  },
  linageSimulation: {
    path: '/assetAnalysis/linageSimulation',
    name: '代码血缘',
  },
  sqlFormConversion: {
    path: '/assetAnalysis/sqlFormConversion',
    name: '代码血缘',
  },
  // 应用中心 ----------- end

  // 系统管理 ----------- start
  permissionsSetting: {
    path: '/system/permissions',
    name: '权限管理',
  },
  tokenManage: {
    path: '/system/tokenManage',
    name: 'API 密钥管理',
  },

  dataSource: {
    path: '/system/dataSource',
    name: '数据源',
  },
  // 系统管理 ----------- end
};
export const routes: IRouteItem[] = [
  // 首页
  {
    path: routerMap.home.path,
    key: routerMap.home.path,
    title: routerMap.home.name,
    pathName: routerMap.home.name,
    component: '@/pages/Home',
  },

  // 数据资产--首页 ----------- start
  {
    path: routerMap.dataAssetHome.path,
    key: routerMap.dataAssetHome.path,
    title: routerMap.dataAssetHome.name,
    pathName: routerMap.dataAssetHome.name,
    component: '@/pages/DataAsset/Home',
  },
  {
    path: routerMap.dataAssetTable.path,
    key: routerMap.dataAssetTable.path,
    title: routerMap.dataAssetTable.name,
    pathName: routerMap.dataAssetTable.name,
    component: '@/pages/DataAsset/Table',
  },
  {
    path: routerMap.dataAssetTask.path,
    key: routerMap.dataAssetTask.path,
    title: routerMap.dataAssetTask.name,
    pathName: routerMap.dataAssetTask.name,
    component: '@/pages/DataAsset/Task',
  },
  {
    path: routerMap.dataAssetCustom.path,
    key: routerMap.dataAssetCustom.path,
    title: routerMap.dataAssetCustom.name,
    pathName: routerMap.dataAssetCustom.name,
    component: '@/pages/DataAsset/Custom',
  },
  // 数据资产 ----------- end

  // 资产分析 --》 资产盘点 ----------- start
  {
    path: routerMap.changeImpactAnalysis.path,
    key: routerMap.changeImpactAnalysis.path,
    title: routerMap.changeImpactAnalysis.name,
    pathName: routerMap.changeImpactAnalysis.name,
    component: '@/pages/AssetAnalysis/ChangeImpactAnalysis/Index',
  },
  {
    path: routerMap.changeImpactAnalysisDetail.path,
    key: routerMap.changeImpactAnalysisDetail.path,
    title: routerMap.changeImpactAnalysisDetail.name,
    pathName: routerMap.changeImpactAnalysisDetail.name,
    component: '@/pages/AssetAnalysis/ChangeImpactAnalysis/Detail',
  },
  {
    path: routerMap.lineageReport.path,
    key: routerMap.lineageReport.path,
    title: routerMap.lineageReport.name,
    pathName: routerMap.lineageReport.name,
    component: '@/pages/AssetAnalysis/LineageReport/Index',
  },
  {
    path: routerMap.traceAnalysis.path,
    key: routerMap.traceAnalysis.path,
    title: routerMap.traceAnalysis.name,
    pathName: routerMap.traceAnalysis.name,
    component: '@/pages/AssetAnalysis/TraceAnalysis/Index',
  },
  {
    path: routerMap.cropAnalysis.path,
    key: routerMap.cropAnalysis.path,
    title: routerMap.cropAnalysis.name,
    pathName: routerMap.cropAnalysis.name,
    component: '@/pages/AssetAnalysis/CropAnalysis/Index',
  },
  // 资产分析 ----------- end

  // 资产管理--》资产打标 ----------- start
  {
    path: routerMap.tagSetting.path,
    key: routerMap.tagSetting.path,
    title: routerMap.tagSetting.name,
    pathName: routerMap.tagSetting.name,
    component: '@/pages/AssetManagement/Tag',
  },
  {
    path: routerMap.tagSettingDetail.path,
    key: routerMap.tagSettingDetail.path,
    title: routerMap.tagSettingDetail.name,
    pathName: routerMap.tagSettingDetail.name,
    component: '@/pages/AssetManagement/Tag/Detail',
  },
  // 资产管理 ----------- end

  // 场景中心 ----------- start
  {
    path: routerMap.sceneCenter.path,
    key: routerMap.sceneCenter.path,
    title: routerMap.sceneCenter.name,
    pathName: routerMap.sceneCenter.name,
    component: '@/pages/SceneCenter/Home',
  },
  {
    path: routerMap.linageSimulation.path,
    key: routerMap.linageSimulation.path,
    title: routerMap.linageSimulation.name,
    pathName: routerMap.linageSimulation.name,
    component: '@/pages/SceneCenter/LinageSimulation',
  },
  {
    path: routerMap.sqlFormConversion.path,
    key: routerMap.sqlFormConversion.path,
    title: routerMap.sqlFormConversion.name,
    pathName: routerMap.sqlFormConversion.name,
    component: '@/pages/SceneCenter/LineageSql',
  },
  // 场景中心 ----------- end

  // 策略中心 ----------- start
  {
    path: routerMap.policyCenter.path,
    key: routerMap.policyCenter.path,
    title: routerMap.policyCenter.name,
    pathName: routerMap.policyCenter.name,
    component: '@/pages/PolicyCenter/ChangeNotification',
  },
  // 策略中心 ----------- end

  // 采集管理 ----------- start
  {
    path: routerMap.ingestManagement.path,
    key: routerMap.ingestManagement.path,
    title: routerMap.ingestManagement.name,
    pathName: routerMap.ingestManagement.name,
    component: '@/pages/Ingest/Management',
  },
  {
    path: routerMap.ingestManagementDetail.path,
    key: routerMap.ingestManagementDetail.path,
    title: routerMap.ingestManagementDetail.name,
    pathName: routerMap.ingestManagementDetail.name,
    component: '@/pages/Ingest/ManagementDetail',
  },
  {
    path: routerMap.ingestInstanceDetail.path,
    key: routerMap.ingestInstanceDetail.path,
    title: routerMap.ingestInstanceDetail.name,
    pathName: routerMap.ingestInstanceDetail.name,
    component: '@/pages/Ingest/Instance/Detail',
  },
  // 采集管理 ----------- end

  // 系统管理 ----------- start
  {
    path: routerMap.permissionsSetting.path,
    key: routerMap.permissionsSetting.path,
    title: routerMap.permissionsSetting.name,
    pathName: routerMap.permissionsSetting.name,
    component: '@/pages/System/Permissions',
  },
  {
    path: routerMap.tokenManage.path,
    key: routerMap.tokenManage.path,
    title: routerMap.tokenManage.name,
    pathName: routerMap.tokenManage.name,
    component: '@/pages/System/TokenManage',
  },

  {
    path: routerMap.dataEntity.path,
    key: routerMap.dataEntity.path,
    title: routerMap.dataEntity.name,
    pathName: routerMap.dataEntity.name,
    component: '@/pages/Ingest/Entity',
  },
  {
    path: routerMap.dataEntityGroup.path,
    key: routerMap.dataEntityGroup.path,
    title: routerMap.dataEntityGroup.name,
    pathName: routerMap.dataEntityGroup.name,
    component: '@/pages/Ingest/Entity/Group',
  },
  {
    path: routerMap.dataEntityGroupView.path,
    key: routerMap.dataEntityGroupView.path,
    title: routerMap.dataEntityGroupView.name,
    pathName: routerMap.dataEntityGroupView.name,
    component: '@/pages/Ingest/Entity/Group/DatasourceView',
  },
  {
    path: routerMap.dataEntityGroupDetail.path,
    key: routerMap.dataEntityGroupDetail.path,
    title: routerMap.dataEntityGroupDetail.name,
    pathName: routerMap.dataEntityGroupDetail.name,
    component: '@/pages/Ingest/Entity/Group/Detail',
  },
  {
    path: routerMap.dataRelation.path,
    key: routerMap.dataRelation.path,
    title: routerMap.dataRelation.name,
    pathName: routerMap.dataRelation.name,
    component: '@/pages/Ingest/Relation',
  },
  {
    path: routerMap.dataRelationDetail.path,
    key: routerMap.dataRelationDetail.path,
    title: routerMap.dataRelationDetail.name,
    pathName: routerMap.dataRelationDetail.name,
    component: '@/pages/Ingest/Relation/Detail',
  },
  {
    path: routerMap.dataRelationDetailView.path,
    key: routerMap.dataRelationDetailView.path,
    title: routerMap.dataRelationDetailView.name,
    pathName: routerMap.dataRelationDetailView.name,
    component: '@/pages/Ingest/Relation/Detail/DatasourceView',
  },
  {
    path: routerMap.dataSource.path,
    key: routerMap.dataSource.path,
    title: routerMap.dataSource.name,
    pathName: routerMap.dataSource.name,
    component: '@/pages/System/Datasource',
  },
  // 系统管理 ----------- end

  {
    path: '/',
    redirect: routerMap.home.path,
  },
];
