import { TNodeItem, TNodeViewItem } from '../typings';
import { commonSplitGuid } from '@/utils';
import Column from '@/components/TaskLineageView/model/Column';
import { mat4, vec3 } from 'gl-matrix';
import { DisplayObject, Group } from '@aloudata/ink';
import { isNil } from 'lodash';
import { ITaskSqlListItem } from '../atoms/overviewAtom';
import Table from '../model/Table';
// import { notification } from '@aloudata/aloudata-design';
import { toast as message } from 'react-toastify';
import { EDirection } from '@/typings';

export function getTransform(transform: mat4) {
  const translate = mat4.getTranslation(vec3.create(), transform);
  return [translate[0], translate[1]];
}

export function getDatabaseNameByGuid(guid: string) {
  if (guid.includes('.')) {
    const [, , , databaseName, tableName] = commonSplitGuid(guid);

    return { databaseName, tableName };
  }

  return { databaseName: '', tableName: '' };
}

// 获取边的两端节点
export function getEdgeEndNode(
  src: TNodeItem,
  dst: TNodeItem,
): {
  srcNode: TNodeViewItem;
  dstNode: TNodeViewItem;
} {
  if (isNil(src) || isNil(dst)) return;

  let srcNode = src.view;
  let dstNode = dst.view;

  if (src instanceof Column && !src.parent.isOpen) {
    srcNode = src.parent.view;
  }

  if (dst instanceof Column && !dst.parent.isOpen) {
    dstNode = dst.parent.view;
  }

  return { srcNode, dstNode };
}

// 更新table footer
export function updateTableFooter(colGroup: Group, footerGroup: Group) {
  const colGHeight = colGroup.getBBox().height; // 列group的高度
  footerGroup.style.y = colGHeight;

  // footerGroup.update();
}

/**
 * 获取文字在dom中的实际宽度
 *
 * @export
 * @param textCanvasCtx
 * @param {string} text
 * @return {*}
 */
export function getTextDomWidth(
  textCanvasCtx: CanvasRenderingContext2D,
  text: string,
) {
  textCanvasCtx.font = `12px PingFang SC`;
  const metrics = textCanvasCtx.measureText(text);
  return metrics.width;
}

export function hideElement(element: DisplayObject) {
  element.setAttribute('visibility', 'hidden');
}

export function showElement(element: DisplayObject) {
  element.setAttribute('visibility', 'visible');
}

// 获取元素的transform
export function getElementTransform(element: DisplayObject) {
  return element.getLocalTransform();
}

/**
 * 根据给定的宽度，超过宽度则打省略号，返回 Text 所需的样式
 */
export function getEllipsisStyle(width: number) {
  return {
    wordWrap: true,
    wordWrapWidth: width,
    textOverflow: 'ellipsis',
    maxLines: 1,
  };
}

// 根据当前点击的资产id,记录侧边栏需要高亮的sqlId
export function getActiveSqlIdsById(
  id: string,
  taskSqlList: ITaskSqlListItem[],
  direction: EDirection,
) {
  const activeSqlIds: string[] = [];
  taskSqlList.forEach((item) => {
    // 溯源 INPUT 的时候，判断outputEntity
    if (direction === EDirection.INPUT && item.outputEntities.includes(id)) {
      activeSqlIds.push(item.sqlId);
      return;
    }

    // 影响 OUTPUT 的时候，判断inputEntitySet
    if (direction === EDirection.OUTPUT && item.inputEntities.includes(id)) {
      activeSqlIds.push(item.sqlId);
    }
  });
  return activeSqlIds;
}

// 排序
export function nodesSort(nodes: Table[]) {
  nodes.forEach((node) => {
    const { children } = node;
    if (children.length) {
      children.sort((childA, childB) => {
        return childA._data_.position - childB._data_.position;
      });
    }
  });
}

export function showExploreTipNotInActiveElement() {
  message.info(`请退出当前探索，并重新选择输入资产或输出资产进行探索`);
}

export function showExploreTipNoSql() {
  message.info(`选中资产在探索链路中没有相关代码，请重新选择资产查看裁剪代码`);
}

export function showIndirectLineage(direction: EDirection) {
  message.info(
    `该节点无直接影响${
      direction === EDirection.INPUT ? '上' : '下'
    }游，请打开间接血缘后查看`,
    {
      position: 'bottom-right',
    },
  );
  // notification.open({
  //   duration: 3,
  //   message: `该节点无直接影响${
  //     direction === EDirection.INPUT ? '上' : '下'
  //   }游，请打开间接血缘后查看`,
  //   // className: styles.notification,
  //   placement: 'bottomRight',
  // });
}

export * from './node';
