import { IUser } from './user';

export enum ESupportedLanguage {
  zhCN = 'zh-CN',
  // enUS = "en-US",
}

// 统一的接口返回格式

export interface IResponse<T> {
  data: T;
  success: boolean;
  message?: string;
  code: string;
  errorMsg?: string;
  errorCode?: string;
}

export interface IHeader {
  [key: string]: string;
}

export enum EAssetType {
  TABLE = 'TABLE',
  CUSTOM = 'CUSTOM',
  TASK = 'TASK',
}

// 数据源类型
export enum EDataSourceType {
  MYSQL = 'MYSQL',
  MARIADB = 'MARIADB',
  GAUSS = 'GAUSS',
  HIVE = 'HIVE',
  KAFKA = 'KAFKA',
  POSTGRE = 'POSTGRE',
  POSTGRESQL = 'POSTGRESQL',
  SNOWFLAKE = 'SNOWFLAKE',
  IMPALA = 'IMPALA',
  ORACLE = 'ORACLE',
  SPARK = 'SPARK',
  TERADATA = 'TERADATA',
  OTHER = 'OTHER',
}

export const dataSourceTypeText = {
  [EDataSourceType.MYSQL]: 'MySQL',
  [EDataSourceType.MARIADB]: 'MariaDB',
  [EDataSourceType.GAUSS]: 'Gauss',
  [EDataSourceType.HIVE]: 'Hive',
  [EDataSourceType.KAFKA]: 'Kafka',
  [EDataSourceType.POSTGRE]: 'Postgre',
  [EDataSourceType.POSTGRESQL]: 'PostgreSQL',
  [EDataSourceType.SNOWFLAKE]: 'Snowflake',
  [EDataSourceType.IMPALA]: 'Impala',
  [EDataSourceType.ORACLE]: 'Oracle',
  [EDataSourceType.SPARK]: 'Spark',
  [EDataSourceType.TERADATA]: 'Teradata',
  [EDataSourceType.OTHER]: 'Other',
};

// 资产类型
export enum EAssetsType {
  TABLE = 'TABLE', // 表
  VIEW = 'VIEW', // 视图
  TEMP_TABLE = 'TEMP_TABLE', // 临时表
  SQL = 'SQL', // 任务资产
  TASK = 'TASK', // 任务
  COLUMN = 'COLUMN',
  TEMP_COLUMN = 'TEMP_COLUMN',
  CUSTOM = 'CUSTOM', // 自定义资产
}

export enum EEntityType {
  TABLE = 'TABLE',
  COLUMN = 'COLUMN',
  CUSTOM = 'CUSTOM',
  TASK = 'TASK',
  VIEW = 'VIEW',
}

export interface IEntity {
  guid: string;
  superGuid?: string;
  superEntityType: EEntityType;
  superTypeCode: string;
  entityType: EEntityType;
  name: string;
  assetPath: string;
  typeCode: string;
  typeCodeName: string;
  datasourceType?: EDataSourceType;
  description: string;
  displayName: string;
}

// 侧边栏的标签
export interface ISidebarTagItem {
  name: string;
  code: string;
  owner: IUser;
  description: string;
  markType: string[];
  markTime: number;
}

export enum EDirection {
  OUTPUT = 'OUTPUT', // 下游
  INPUT = 'INPUT', // 上游
  BOTH = 'BOTH',
}
