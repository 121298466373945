export const tag = {
  tagCode: '标签ID',
  tagName: '标签名称',
  tagDescription: '标签描述',
  addText: '新建标签',
  addMessage: '新建标签成功',
  editMessage: '编辑标签成功',
  deleteText: '删除标签',
  deleteMessage: '删除标签成功',
  deleteTips: '删除标签将无法恢复，确定删除吗？',
  tagNameRegText: '请输入数字、中文、英文字母、下划线，并且最大长度为50',
  tagDescRegText: '最大长度为100',
  tagCodeRegText: '请输入英文字母，数字，并且最大长度为32',

  addTagTaskText: '添加打标任务',
  addTagType: '打标方式',
  tagTaskType: '任务类型',
  assetsTag: '资产打标',
  assetsType: '资产类型',
  assetsTypeText: '选择种子资产',
  directTagText: '直接打标',
  directTagDescDiffuse:
    '直接打标有扩散：在用户提供的资产上直接进行打标，并且通过血缘扩散标签',
  directTagDescNoDiffuse:
    '直接打标无扩散：在用户提供的资产上直接进行打标，并且不通过血缘扩散标签',
  logicTagDescNoDiffuse:
    '算法打标无扩散：依据系统提供的算法策略识别资产进行打标，并且不通过血缘扩散标签',
  logicTagText: '算法打标',
  deleteTaskText: '删除任务',
  deleteTaskTips: '删除任务将无法恢复，确定删除吗？',
  addTagDepends: '打标依据',
  cancelRelatedOkText: '撤销',
  cancelRelatedText: '撤销关联',
  cancelRelatedTips:
    '该操作会撤销当前资产关联的标签并加入黑名单，撤销关联后将无法恢复，确认撤销关联吗？',
  tagResultModalTitle: '查看打标结果',
  tagResultModalTableTotal: (total: number) => {
    return `共 ${total} 个资产`;
  },
  tagDiffuse: '标签扩散',
  hotIdentification: '热点识别',
  hotIdentificationText: '热点资产识别',
  addTagTime: '打标时间',
  resourceTitle: '查看扩散来源',
  viewTagTaskTitle: '查看打标任务',
  addTagTaskTitle: '添加打标任务',
  orText: '或',
  andText: '且',
  algorithmType: '算法策略',
  strategyParameters: '策略参数',
  paramsNumText: '下游 1 层资产数量大于',
  paramsNumTextTip: '请填写大于0的资产层级',

  diffuseLineage: '按血缘扩散标签',
  diffuseTypeText: '扩散条件',
  diffuseAllText: '全部扩散',
  diffusePartText: '条件扩散',
  hadDiffuse: '有扩散',
  noDiffuse: '无扩散',
  diffuseRange: '扩散范围',
  diffuseDirection: '扩散方向',
  conditionOptionsPlaceholder: '请选择判断依据',
  operatorOptionsPlaceholder: '请选择操作符号',
  assetsEmptyText: '选择种子资产后配置扩散规则',
  tips: (hasValue: boolean) => {
    return hasValue
      ? '热点资产识别算法策略不支持标签扩散'
      : '依据选择的算法策略判断是否支持标签扩散';
  },
  autoUpdateText: '血缘更新后打标结果自动更新',
  autoRemoveTagText: '血缘更新后自动撤销标签关联',
  tagTableTotal: (total: number) => {
    return `共 ${total} 个标签`;
  },
  tagSubTips: (num: number) => {
    return `该标签存在 ${num} 个关联资产，请谨慎删除`;
  },
  monitorTip: (num: number) => {
    return `当前标签已经配置 ${num} 个变更监控，请优先删除对应监控配置后再删除标签。`;
  },
  tagTaskTableTotal: (total: number) => {
    return `共 ${total} 个任务`;
  },
  tagTaskSubTips: (num: number) => {
    return `该任务存在 ${num} 个关联资产，请谨慎删除`;
  },
  directLineageAssetsText: '直接血缘涉及资产',

  tagBlacklist: '标签黑名单',
  owner: '操作人',
  operateTime: '操作时间',
  blacklistTotal: (num: number) => {
    return `共 ${num} 个黑名单资产`;
  },

  markTagName: '打标任务名称',
  diffuseAccuracy: '扩散精度',
  diffuseTable: '按表扩散',
  diffuseColumn: '按字段扩散',

  lastCompletedTime: '最近运行完成时间',
  assetListEmptyText: '请选择种子资产',
  removeFromBlacklist: '移出黑名单',
  removeFromBlacklistSureText: '是否确认将',
  removeFromBlacklistText: '从黑名单中移出？',

  guidEmpty: '不存在资产guid',
};
