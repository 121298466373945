import { EOverviewType, IOverView } from '@/typings/overview';
import Mitt, { Emitter } from 'mitt';
import Layout from '../layout';
import { ELayoutType } from '../layout/type';
import {
  EDashType,
  EEventEnum,
  Events,
  IContextmenuState,
  IPopover,
  ISideIconState,
  IUserEdge,
  IUserTable,
  TActiveItem,
  TNodeItem,
} from '../typings';
import { EDirection } from '@/typings';
import Column from './Column';
import Graph from './Graph';
import Table from './Table';
import { Event } from '@aloudata/ink';
import {
  getColumnPathNodesEdges,
  removeGraphByTable,
} from '@/components/TaskLineageView/utils/lineage';
import SubGraph from '@/components/TaskLineageView/model/SubGraph';
import { overviewDefaultData } from './modalConfig';
import { EElementType } from '@/services/lineage/type';
import Edge from './Edge';
import {
  EGraphMode,
  IOverviewState,
  ITaskSqlListItem,
} from '@/components/TaskLineageView/atoms/overviewAtom';
import { getTaskLineageSqlList } from '@/services/taskLineage';
import { getParamsFromHash } from '@/utils';
import _ from 'lodash';
import TableView from '../view/TableView';
import ColumnView from '../view/ColumnView';
import { mat4, vec3 } from 'gl-matrix';
import { TABLE_WIDTH } from '../view/TableView/style';
import { getLinageSimulationSqlList } from '@/services/sceneCenter';
import { toast as message } from 'react-toastify';

export type TNodeMap = Map<string, TNodeItem>;
export type TEdgeMap = Map<string, Edge>;

export default class SQLGraph {
  // 消息总线，为了配合recoil使用
  emitter: Emitter<Events>;

  // 主画布
  viewGraph!: Graph;

  // 子画布
  subViewGraph!: SubGraph;

  layoutType: ELayoutType = ELayoutType.DAGRE;

  // 概览，暂时这样迁移，后面重写
  overviewState: IOverView = overviewDefaultData;

  // 加工口径在点击列或者右键的时候，预设的信息，用于后面点击加工口径解析打开弹窗的props
  columnCaliberConfig: {
    columnGuid: string;
    columnName: string;
    tableName: string;
    colDes?: string;
  } = {
    columnGuid: '',
    columnName: '',
    tableName: '',
    colDes: '',
  };

  popoverState: IPopover = {
    title: '',
    visible: false,
    content: '',
    left: 0,
    top: 0,
  };

  // 起始表guid
  startNodeGuid: string;

  // 右键菜单
  contextmenuState: IContextmenuState = {
    visible: false,
    guid: '',
    left: 0,
    top: 0,
    type: EElementType.TABLE,
    typeCode: '',
  };

  exploreState: IOverviewState = {
    // 是否为探索模式
    mode: EGraphMode.NORMAL,

    // 全链路SQL list
    taskSqlList: [],

    // 侧边栏高亮的sqlId
    activeSqlIds: [],
    exploreDirection: null,
  };

  // hover 血缘两侧的图标状态
  private sideIconState: ISideIconState = {};

  // hover 血缘两侧的图标状态
  operatorId: string = '';

  // 任务id
  taskId: string = '';

  showDatabaseFullName: boolean = false;

  root: HTMLElement;

  constructor() {
    this.emitter = Mitt();
  }

  update(...events: EEventEnum[]) {
    events.forEach((event) => {
      this.emitter.emit(event);
    });
  }

  // 更新概览信息
  setOverview(data: IOverView) {
    this.overviewState = data;
    this.update(EEventEnum.OVERVIEW_CHANGE);
  }

  // 设置起始表的guid
  setStartNodeGuid(guid: string) {
    this.startNodeGuid = guid;
  }

  getSideState() {
    return this.sideIconState;
  }

  // 展开列口径摘要和列描述
  setPopover(data: IPopover) {
    this.popoverState = data;

    this.update(EEventEnum.POPOVER_CHANGE);
  }

  // 收起列口径摘要和列描述
  hidePopover() {
    this.popoverState.visible = false;
    this.update(EEventEnum.POPOVER_CHANGE);
  }

  // 修改探索模式数据
  changeExploreState(data: IOverviewState) {
    this.exploreState = data;
    this.update(EEventEnum.EXPLORE_CHANGE);
  }

  // 显示右键菜单
  showContextmenu(data: IContextmenuState) {
    this.contextmenuState = data;
    this.update(EEventEnum.CONTEXT_MENU_CHANGE);
  }

  // 隐藏右键菜单
  hideContextmenu() {
    this.contextmenuState.visible = false;
    this.update(EEventEnum.CONTEXT_MENU_CHANGE);
  }

  // 设置加工口径解析的预设信息
  setColumnCaliberObj(data: {
    columnGuid: string;
    columnName: string;
    tableName: string;
    colDes?: string;
  }) {
    this.columnCaliberConfig = data;
  }

  init(
    nodes: IUserTable[],
    edges: IUserEdge[],
    root: HTMLElement,
    taskGuid: string,
    edgeDashVisible?: EDashType,
  ) {
    this.root = root;
    this.taskId = taskGuid;
    this.viewGraph = new Graph();
    if (edgeDashVisible) this.viewGraph.edgeDashVisible = edgeDashVisible;

    this.viewGraph.read(nodes, edges, this, root);
    this.createSubViewGraph();
    this.rerender();
    this.initListener();

    // 默认探索目标列
    this.exploreOriginColumn();
  }

  reset() {
    this.root.innerHTML = '';
    this.viewGraph = null;
    this.subViewGraph = null;
  }

  checkIsFlush(event: Event) {
    const isSvgElement = (event.target as HTMLElement).tagName === 'svg';
    if (!isSvgElement) {
      return;
    }
  }

  initListener() {
    this.viewGraph.initListener();
  }

  rerender() {
    // 重新计算相关列
    // refreshCorrelation(this);
    this.reBuildNodes();
    this.viewGraph.rerender();
    this.layout();
    this.viewGraph.renderEdges();
    this.clearInvalidEdge();
  }

  reBuildNodes() {
    // filter visible nodes
    const newNodes = this.viewGraph.nodes.filter((node) => {
      return node.getVisible(); // TODO
    });

    // update valid nodes
    this.viewGraph.setNodes(newNodes);
  }

  layout(layout: ELayoutType = this.layoutType) {
    const graph = this.viewGraph;
    const { wrapper, nodes, samePosEdgeMap } = graph;

    const pos = Layout.run(this, {
      width: wrapper.context.config.width,
      height: wrapper.context.config.height,
      layout,
    });

    nodes.forEach((node, i) => {
      if (pos && pos[i]) {
        node.setPos(pos[i]);
        node.view?.g.setPosition(pos[i]);
      }
    });

    samePosEdgeMap.clear();

    // originEdgeMap.forEach((edge) => {
    //   edge.view?.rerender();
    // });

    this.subViewGraph.rerender();
  }

  clearInvalidEdge() {
    const { edges } = this.viewGraph;
    edges.forEach((edge) => {
      if (!edge.view) return;
      if (!edge.view.isVisible()) {
        edge.view.destroy();
      }
    });
  }

  createSubViewGraph() {
    this.subViewGraph = new SubGraph(
      document.getElementById('TaskLineageView2-highlight'),
      this,
    );
  }

  // 更新高亮逻辑
  updateActiveItem(item: TActiveItem, direction = EDirection.BOTH) {
    // refreshCorrelation(this, false);
    this.subViewGraph.updateActiveItem(item, direction);
  }

  // 更新右键高亮逻辑
  updateRightClickActiveItem(item: TActiveItem) {
    this.subViewGraph.updateRightClickActiveItem(item);
  }

  resetActiveItem(item: TActiveItem) {
    this.subViewGraph.resetActiveItem(item);
  }

  resetAllHighLight() {
    this.subViewGraph.currentClickId = [];
    this.subViewGraph.nodes = [];
    this.subViewGraph.edges = [];
    this.subViewGraph.removeActiveElements();
  }

  dellColumnExpand(column: Column) {
    const { nodes } = getColumnPathNodesEdges(
      [column],
      this.viewGraph.originEdgeMap,
      this.viewGraph.originNodeMap,
      EDirection.BOTH,
      Number.MAX_SAFE_INTEGER,
      {
        edgeType: this.viewGraph.edgeDashVisible,
      },
    );

    // 把扩展出来的表打开
    nodes.forEach((node) => {
      if (node instanceof Column) {
        node.parent.isOpen = true;
        if (node.parent.isShowAll) {
          node.parent.isShowAll = false;
        }
      }
    });

    // 扩展后把当前的显示全部列收起
    if (column.isStartNode) column.parent.isShowAll = false;
    // 触发一次高亮
    this.resetActiveItem(column);
    this.rerender();
  }

  // 改变扩展收起上下游图标
  changeSideIconState(data: ISideIconState) {
    // 数据合并
    Object.assign(this.sideIconState, data);

    // 下面事件需要修改
    this.update(EEventEnum.SIDE_ICON_STATE_CHANGE);
  }

  expandGraphData(nodeData: IUserTable[], edgeData: IUserEdge[]) {
    // 去重已经存在的边，避免重复渲染
    const edgeDeduplication = edgeData.filter((item) => {
      return !this.viewGraph.originEdgeMap.has(`${item.srcId}-${item.dstId}`);
    });
    this.viewGraph.update(nodeData, edgeDeduplication);
    this.rerender();
  }

  // 收起上下游
  removeGraphData(startNode: TNodeItem, direction: EDirection) {
    if (startNode instanceof Table) {
      removeGraphByTable([startNode], direction, this);
      this.rerender();
    }
  }

  // 列收起上下游
  // removeColumns(startNodes: Column[], direction: EDirection) {
  //   removeColumnByNode(startNodes, direction, this);
  //   this.rerender();
  // }

  searchColumn(input: string) {
    this.subViewGraph.resetHighLightColumns();

    const id = input.trim();
    if (!id || id.length < 1) return;

    // 找出所有匹配的column 并展开对应的table
    const res: TNodeItem[] = [];
    this.viewGraph.originNodeMap.forEach((item) => {
      if (item.name.indexOf(id) > -1) {
        if (item instanceof Column) {
          item.parent.isOpen = true;
          item.parent.isShowAll = true;
        }
        res.push(item);
      }
    });

    this.rerender();
    // 绘制每个column的高亮框
    if (res.length < 1) return;

    this.subViewGraph.searchHighLightNode(res);
    return res.length;
  }

  // 更改是否只显示直接关系
  updateEdgeDashVisible(edgeDashVisible: EDashType) {
    this.viewGraph.edgeDashVisible = edgeDashVisible;
    if (this.exploreState.mode === EGraphMode.EXPLORE) {
      this.updateGraphByDashType();
    } else {
      // this.refresh(edgeDashVisible);
      this.rerender();
    }
  }

  // async refresh(edgeDashVisible: EDashType) {
  //   const params: ILineageQuery = {
  //     depth: 3,
  //     direction: EDirection.INPUT,
  //     taskGuid: this.taskId,
  //     relations: [ERelationType.TableDirectTable],
  //     entityType: 'Table',
  //     needIndirect:
  //       this.viewGraph.edgeDashVisible === EDashType.DIRECT ? false : true,
  //   };

  //   // 同时请求改成串行请求
  //   const newData = await getLineageDataV2(params);
  //   const parseData = parseResultData(newData);

  //   const { edges, nodes } = parseData;
  //   if (nodes.length > 0) {
  //     this.reset();
  //     this.init(nodes, edges, this.root, this.taskId, edgeDashVisible);
  //   }
  // }

  updateShowFullName(val: boolean) {
    this.showDatabaseFullName = val;
    this.rerender();
  }

  updateShowRelatedTable(val: boolean) {
    this.viewGraph.showRelatedTable(val);
    this.rerender();
  }

  // 重新设置画布大小
  svgWrapperResize() {
    const svgWrapper = document.getElementById('TaskLineageView2');
    if (svgWrapper) {
      const width = svgWrapper.clientWidth;
      const height = svgWrapper.clientHeight;
      this.viewGraph?.wrapper?.resize?.(width, height);
    }
  }

  // FIXME: 临时新增方法，避免影响原有链路，后续优化
  async updateGraphByDashType() {
    this.hideContextmenu();

    const guid = this.exploreState.exploreAssetId;
    const { type } = this.exploreState;
    const direction = this.exploreState.exploreDirection;
    const nodeView = this.exploreState.nodeView;

    // right panel loading
    this.setOverview({
      ...this.overviewState,
      showLoading: true,
    });

    // fetch data
    try {
      // 表的情况，直接高亮当前关联的所有table
      if (type === EElementType.TABLE) {
        await this.preProcessFn(true);
        await this.getTaskSqlList(direction, guid, type, nodeView);
        this.updateActiveItem(nodeView.props.data, direction);
      } else {
        await this.preProcessFn(false);
        await this.getTaskSqlList(direction, guid, type, nodeView);
        if (direction === EDirection.INPUT) {
          nodeView.leftIcon?.expandGraphByDashType(
            1,
            direction,
            guid,
            nodeView.props.data as Column,
          );
        } else {
          nodeView.rightIcon?.expandGraphByDashType(
            1,
            direction,
            guid,
            nodeView.props.data as Column,
          );
        }
      }

      // 打开侧边栏
      const { name } = nodeView.props.data._data_;
      this.setOverview({
        ...this.overviewState,
        targetId: guid,
        type: type as unknown as EOverviewType,
        visible: true,
        name,
      });
    } catch (e) {
      console.error(e);
    } finally {
      this.setOverview({
        ...this.overviewState,
        showLoading: false,
      });

      // reset position
      this.resetPosition();
    }
  }

  async expandInput() {
    const { guid, type } = this.contextmenuState;
    const lastExploreAssetId = this.exploreState.exploreAssetId;
    if (
      guid === lastExploreAssetId &&
      this.exploreState.mode === EGraphMode.EXPLORE
    )
      return message.info('请勿重复探索');

    const nodeView = this.viewGraph.originNodeMap?.get(guid)?.view;
    this.setOverview({
      ...this.overviewState,
      showLoading: true,
    });

    try {
      // 表的情况，直接高亮当前关联的所有table
      if (type === EElementType.TABLE) {
        this.preProcessFn(true);
        await this.getTaskSqlList(EDirection.INPUT, guid, type, nodeView);
        this.updateActiveItem(nodeView.props.data, EDirection.INPUT);
      } else {
        this.preProcessFn(false);
        await this.getTaskSqlList(EDirection.INPUT, guid, type, nodeView);
        nodeView.leftIcon?.expandGraph(1);
      }

      this.hideContextmenu();
      // 打开侧边栏
      // openOverview();
      const { name } = nodeView.props.data._data_;
      this.setOverview({
        ...this.overviewState,
        targetId: guid,
        type: type as unknown as EOverviewType,
        visible: true,
        name,
      });
    } catch (e) {
      console.error(e);
    } finally {
      this.setOverview({
        ...this.overviewState,
        showLoading: false,
      });

      // reset position
      // this.resetPosition();
    }
  }

  //FIXME: 后续与expandInput合并，通过参数区分调用关系
  async expandOutput() {
    const guid = this.contextmenuState.guid;
    const lastExploreAssetId = this.exploreState.exploreAssetId;
    if (
      guid === lastExploreAssetId &&
      this.exploreState.mode === EGraphMode.EXPLORE
    )
      return message.info('请勿重复分析');

    const { type } = this.contextmenuState;
    const nodeView = this.viewGraph.originNodeMap?.get(guid)?.view;

    this.setOverview({
      ...this.overviewState,
      showLoading: true,
    });

    try {
      // 表的情况，直接高亮当前关联的所有table
      if (type === EElementType.TABLE) {
        this.preProcessFn(true);
        await this.getTaskSqlList(EDirection.OUTPUT, guid, type, nodeView);
        const { context } = nodeView.props;
        context.updateActiveItem(nodeView.props.data, EDirection.OUTPUT);
      } else {
        this.preProcessFn(false);
        await this.getTaskSqlList(EDirection.OUTPUT, guid, type, nodeView);
        nodeView.rightIcon?.expandGraph(1);
      }
      this.hideContextmenu();
      // 打开侧边栏
      // openOverview();
      const { name } = nodeView.props.data._data_;
      this.setOverview({
        ...this.overviewState,
        targetId: guid,
        type: type as unknown as EOverviewType,
        visible: true,
        name,
      });
    } catch (e) {
      console.error(e);
    } finally {
      this.setOverview({
        ...this.overviewState,
        showLoading: false,
      });

      // reset position
      // this.resetPosition();
    }
  }

  getTaskSqlList = async (
    exploreDirection: EDirection,
    guid: string,
    type: string,
    nodeView: TableView | ColumnView,
  ) => {
    try {
      let res: Omit<ITaskSqlListItem, 'sqlId'>[];
      if (this.overviewState.id) {
        res = await getLinageSimulationSqlList({
          id: this.overviewState.id,
          entityGuid: guid,
          direction: exploreDirection,
          needIndirect: this.viewGraph.edgeDashVisible === EDashType.INDIRECT,
        });
      } else {
        res = await getTaskLineageSqlList({
          entityGuid: guid,
          taskGuid: getParamsFromHash(location.hash, 'guid'),
          type,
          direction: exploreDirection,
          needIndirect: this.viewGraph.edgeDashVisible === EDashType.INDIRECT, // new
        });
      }

      const taskSqlList = res.map((item, index) => {
        const {
          outputEntities,
          outputEntityType,
          inputEntityType,
          inputEntities,
          sql,
          relationType,
        } = item;
        // const formatSql = sqlFormatter.format(sql || '');
        const formatSql = sql || '';
        return {
          outputEntities,
          outputEntityType,
          inputEntityType,
          inputEntities,
          sql: formatSql,
          copySql: this.overviewState.id ? sql : formatSql,
          sqlId: `${guid}/${index + 1}`,
          sqlOrder: index + 1,
          relationType,
        };
      });

      // 设置探索模式和存储taskSqlList
      this.changeExploreState({
        ...this.exploreState,
        mode: EGraphMode.EXPLORE,
        taskSqlList,
        activeSqlIds: [],
        exploreDirection,
        exploreAssetId: guid,
        type,
        nodeView,
      });
    } catch (e) {
      console.error(e);
    } finally {
      // const nodeView = this.viewGraph.originNodeMap?.get(guid)?.view;
      this.setOverview({
        ...this.overviewState,
        assetPath: nodeView?.props?.data?.assetPath,
      });
    }
  };

  preProcessFn(foldTable: boolean) {
    this.changeExploreState({
      ...this.exploreState,
      exploreDirection: null,
    });

    // 删除之前 nodes 中highlight 为 true 的数据
    this.viewGraph.nodes.forEach((node) => {
      // 删除 children 中 highlight 为 true 的数据
      _.remove(node.children, (child) => child.highlight);
    });

    this.viewGraph.originNodeMap.forEach((node) => {
      if (node instanceof Table) {
        node.children.forEach((child) => {
          if (child.highlight) {
            child.highlight = false;
          }
        });
      }
      if (node.highlight) {
        this.viewGraph.originNodeMap.delete(node.index);
      }
    });

    _.remove(this.viewGraph.edges, (child) => child.highlight);
    this.viewGraph.originEdgeMap.forEach((node) => {
      if (node.highlight) {
        this.viewGraph.originEdgeMap.delete(node.index);
      }
    });

    this.resetAllHighLight();
    if (foldTable) {
      // 把之前打开的 Table 全部收起来
      this.viewGraph.currentNodeMap.forEach((item) => {
        if (item instanceof Table) {
          item.isOpen = false;
        }
      });
      this.rerender();
    }
  }

  exploreOriginColumn() {
    // get origin column id
    const originColumnGuid = getParamsFromHash(
      window.location.hash,
      'originColumnGuid',
    );

    // get origin table id
    const originTableGuid = getParamsFromHash(
      window.location.hash,
      'originTableGuid',
    );

    const columnData = this.viewGraph.originNodeMap.get(originColumnGuid);
    this.sideIconState.data = columnData;

    if (
      originColumnGuid &&
      originTableGuid &&
      originColumnGuid !== 'undefined' &&
      originTableGuid !== 'undefined'
    ) {
      this.contextmenuState.guid = originColumnGuid;
      this.contextmenuState.type = EElementType.COLUMN;

      this.expandInput();
    }
  }

  resetPosition() {
    this.viewGraph.resetPosition();
  }

  // 判断节点是否在可视区域
  determineNodeIsInView(node: TNodeItem) {
    if (!node) return;

    const target = node.view.bgRect;
    const { height, width } = target.getBBox();
    const [x, y] = target.getPosition();

    // 画布的宽高
    let wrapperWidth = this.viewGraph.wrapper.getConfig().width;
    const wrapperHeight = this.viewGraph.wrapper.getConfig().height;
    const camera = this.viewGraph.wrapper.getCamera();
    if (this.exploreState.mode === EGraphMode.EXPLORE) {
      const sidebarWidth = 650;
      wrapperWidth = wrapperWidth - sidebarWidth;
    }

    const [rootX, rootY] = mat4.getTranslation(
      vec3.create(),
      camera.getOrthoMatrix(),
    );
    const zoom = camera.getZoom();

    const xCenter = (rootX + x + width / 2) * zoom;
    const yCenter = (rootY + y + height / 2) * zoom;

    const isVisible =
      xCenter > 0 &&
      xCenter < wrapperWidth &&
      yCenter > 0 &&
      yCenter < wrapperHeight;

    if (node.view.g.isCulled() || !isVisible) {
      this.viewGraph.moveTargetToCenter(node.view.bgRect);

      const canvasW = this.viewGraph.wrapper.getConfig().width;
      const canvasH = this.viewGraph.wrapper.getConfig().height;

      this.viewGraph.wrapper.getCamera().resetPosition();
      this.viewGraph.wrapper
        .getCamera()
        .pan(
          x - canvasW / 2 + TABLE_WIDTH + width / 2,
          y - canvasH / 2 + height / 2,
        );
    }
  }
}
